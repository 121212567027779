<template>
  <RevCardCarousel
    v-if="hasArticles"
    :id="textToTarget(title, 'heading-2')"
    desktop-card-width="wider"
    :next-alternative-text="i18n(translations.nextSlideText)"
    :prev-alternative-text="i18n(translations.prevSlideText)"
    size="large"
    :subtitle="isHeadingVisible ? subtitle : undefined"
    :title="isHeadingVisible ? title : undefined"
  >
    <PressArticleCard
      v-for="(article, index) in articles"
      :key="article.id"
      class="h-full w-full"
      :data-test="`presscarousel-slide-${index}`"
      v-bind="article"
      :loading="getLoadingAttribute(index)"
      :tracking="track({ index, article })"
    />

    <template v-if="hasPressLink" #button>
      <RevButton size="medium" :to="pressLink" variant="primary">
        {{ i18n(translations.button) }}
      </RevButton>
    </template>
  </RevCardCarousel>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PressArticleContent } from '@backmarket/http-api/src/api-specs-content/models/press-article-content'
import type { PressCarouselProps } from '@backmarket/http-api/src/api-specs-content/models/press-carousel-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevCardCarousel } from '@ds/components/CardCarousel'

import { textToTarget } from '@backmarket/nuxt-layer-cms/utils/textToTarget'

import type { ContentBlockProps } from '../../models/content-block'
import { Loading } from '../../shared-components/ImageRenderer/ImageRenderer.constants'

import PressArticleCard from './PressArticleCard.vue'
import translations from './PressCarousel.translations'
import { PRESS_LINKS_BY_COUNTRY } from './config'

const props = withDefaults(
  defineProps<PressCarouselProps & ContentBlockProps>(),
  {
    title: '',
    subtitle: '',
    articles: () => [],
  },
)

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { countryCode } = useMarketplace().market
const i18n = useI18n()

const pressLink = computed(() => PRESS_LINKS_BY_COUNTRY[countryCode])
const hasPressLink = computed(() => typeof pressLink.value !== 'undefined')

const isHeadingVisible = computed(
  () => hasPressLink.value || props.title !== '',
)
const hasArticles = computed(() => props.articles.length > 0)

const getLoadingAttribute = (index: number): Loading => {
  return index === 0 ? Loading.Eager : Loading.Lazy
}

const track = ({
  index,
  article,
}: {
  index: number
  article: PressArticleContent
}) => {
  return {
    ...props.tracking,
    position: index + 1,
    name: article.text,
    title: props.title,
  }
}
</script>
